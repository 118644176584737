import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '../views/AboutView'
import pcHome from '../views/official/pcHome';
import rechargePage from '@/views/rechargePage.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: ''
    
  },

  {
    path: '',
    name: 'about',
    component: AboutView,
    meta: {
      title: '不止网络' // 设置页面标题为'首页'
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: rechargePage,
    meta: {
      title: '不止网络' // 设置页面标题为'首页'
    }
  },
  {
    path: '/index',
    name: 'pcHome',
    component: pcHome,
    meta: {
      title: '不止网络' // 设置页面标题为'首页'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // document.title = to.meta.title || '默认标题'; // 如果有设置页面标题则使用设置的标题，否则使用默认标题
  const currentDomain = window.location.hostname;
  // let { hostname, pathname } = window.location;
  // hostname.match("bzxd.tv")
  // 根据域名设置不同的页面标题
  if (currentDomain.match("jiubanapp.com")||currentDomain.match("qtqingtou.cn")||currentDomain.match("qingxinliaoqx.cn")||currentDomain.match("xinxinliao.cn")||currentDomain.match("dayliao.cn")||currentDomain.match("joyliao.cn")) {
    document.title = '海南豪旺';
  }else if(currentDomain.match("huanyuanapp.cn")){
    document.title = '满乐游网络';
  } else if(currentDomain.match("youyuanonline.cn")){
    document.title = '满乐游科技';
  }else if(currentDomain.match("hnzhengqiang.cn")||currentDomain.match("hehuanapp.cn")||currentDomain.match("huanyueapp.cn")){
    document.title = '海南正强网络科技有限公司';
  } else if ( currentDomain.match("ruyuanapp.cn")||currentDomain.match("jiuyuanapp.cn")) {
    document.title = '海南尼豆网络科技有限公司';
  } else if ( currentDomain.match("changyuanapp.cn")||currentDomain.match("qingqianapp.cn")||currentDomain.match("ylyulian.cn")||currentDomain.match("yuanquapp.cn")||currentDomain.match("yuyuanyy.cn")) {
    document.title = '上海璟栝网络科技有限公司';
  }else if ( currentDomain.match("wanmeiwanban.cn")) {
    document.title = '完美玩伴';
  } else  {
    document.title = '不止心动';
  }
  // 继续路由导航
  next();
});

export default router
