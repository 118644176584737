<template>
    <div >
        <div class="topinput">
            <van-cell-group>
            <van-field
                type="textarea"
                v-model="message2"
                label="留言"
                placeholder="请输入留言"
                rows="2"
                autosize
                maxlength="50"
                show-word-limit
            />
            </van-cell-group>
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        images: {
        },
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
 
  </style>
  