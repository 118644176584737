<template>
  <div class="privacy-terms">
    <div class="terms-container">
      <h1 class="title">服务条款</h1>
      
      <div class="section">
        <h2>1、服务条款的接受</h2>
        <p>欢迎使用完美玩伴，完美玩伴是约会交友活动的综合信息服务平台。使用完美玩伴的服务之前代表您承诺遵守本服务条款。</p>
      </div>

      <div class="section">
        <h2>2、服务说明</h2>
        <p>完美玩伴通过互联网为用户提供网络服务，包括在线及无线的相关业务。</p>
        <p>完美玩伴在提供网络服务时，可能会对部分网络服务收取一定的费用，在此情况下，会在相关页面上做明确的提示。如用户拒绝支付该等费用，则不能使用相关的网络服务。</p>
        <p>完美玩伴保留不经事先通知因维修、升级或其它原因暂停本服务任何部分的权利。</p>
        <p>完美玩伴所提供的所有服务及以后将提供的服务都将受到本服务条款的约束。</p>
      </div>

      <div class="section">
        <h2>3、用户的注册义务</h2>
        <p>为了能使用本服务，您同意以下事项：依本服务注册提示提供您本人真实、正确、最新及完整的资料（以下简称"登记资料"）；随时更新登记资料，确保其有效性。若您提供任何错误、不实或不完整的资料或者完美玩伴有理由怀疑资料为错误、不实或不完整的，完美玩伴有权暂停或终止您的帐号，并拒绝您现在和未来使用本服务之全部或任何部分。</p>
      </div>

      <div class="section">
        <h2>4、隐私保护</h2>
        <p>尊重用户个人隐私是完美玩伴的一项基本原则，您的个人资料的使用将受到中国有关隐私的法律和本公司《隐私保护声明》之规范。</p>
      </div>
      <div class="section">
        <h2>5、用户的帐号、密码和安全性</h2>
        <p>您一旦注册成功成为用户，您将得到一个密码和帐号。如果您未保管好自己的帐号和密码而对您、完美玩伴或第三方造成的损害，您将负全部责任。另外，每个用户都要对其帐户中的所有活动和言论负全责。</p>
        <p>您可随时改变您的密码和图标，也可以结束旧的帐户重开一个新帐户。</p>
        <p>用户同意若发现任何非法使用用户帐号或安全漏洞的情况，应该立即通告完美玩伴。</p>
      </div>
      <div class="section">
        <h2>6、用户行为</h2>
        <p>用户单独承担发布内容的责任。用户对服务的使用是根据所有适用于服务的地方法律、国家法律和国际法律标准的。用户承诺：</p>
        
        <div class="subsection">
          <p>（1）发布信息或者利用完美玩伴的服务时必须符合中国有关法规，不得在完美玩伴的网页上或者利用完美玩伴的服务制作、复制、发布、传播以下信息：</p>
          <div class="list">
            <p>(a) 反对宪法所确定的基本原则的；</p>
            <p>(b) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
            <p>(c) 损害国家荣誉和利益的；</p>
            <p>(d) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p>(e) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
            <p>(f) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
            <p>(g) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
            <p>(h) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
            <p>(i) 含有法律、行政法规禁止的其他内容的。</p>
          </div>
        </div>

        <div class="subsection">
          <p>（2）在完美玩伴的网页上发布信息或者利用完美玩伴的服务时还必须符合其它有关国家和地区的法律规定以及国际法的有关规定。</p>
        </div>

        <div class="subsection">
          <p>（3）不利用完美玩伴的服务从事以下活动：</p>
          <div class="list">
            <p>(a) 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
            <p>(b) 未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
            <p>(c) 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
            <p>(d) 故意制作、传播计算机病毒等破坏性程序的；</p>
            <p>(e) 其他危害计算机信息网络安全的行为。</p>
          </div>
        </div>

        <div class="subsection">
          <p>（4）不以任何方式干扰完美玩伴的服务。</p>
          <p>（5）遵守完美玩伴的所有其它规定和程序。</p>
        </div>

        <p class="important-notice">用户需对自己在使用完美玩伴服务过程中的行为承担法律责任。用户理解，如果完美玩伴发现其网站传输的信息明显属于上段第(1)条所列内容之一，依据中国法律，完美玩伴有义务立即停止传输，保存有关记录，向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。若用户的行为不符合以上提到的服务条款，完美玩伴将作出独立判断立即取消用户服务帐号。</p>
        
        <p class="important-notice">用户确认，除法律法规明确规定或本协议另有约定外，用户已购买的任何收费服务（包括已经购买的VIP会员服务、充值金币服务等）不能以任何理由退购或换成其他服务。如用户自身违法违规行为或其他自身原因（包括但不限于违反本协议第6条规定）导致完美玩伴账号丢失、被封禁或冻结的，由用户自行承担账户丢失、封禁等所带来的一切损失，完美玩伴对于用户自身行为导致的账户损失问题不承担任何责任。因此导致完美玩伴损失的，完美玩伴还有权要求用户承担包括律师费、诉讼费、保全费等在内的所有损失。</p>
      </div>
      <div class="section">
        <h2>7、内容的所有权</h2>
        <div class="subsection">
          <p>（1）在本服务条款中，"本服务公开使用区域"系指一般公众可以使用的区域。</p>
          <p>（2）您同意已就您于本服务公开使用区域及本服务其他任何公开使用区域张贴之内容，或包括照片、图形、影音资料或地理位置等内容，授予完美玩伴全球性、免许可费及非独家的使用权，完美玩伴可以为了展示、散布及推广张贴前述内容之特定服务目的，将前述内容复制、修改、改写、改编或出版，在您将前述内容放入本服务期间，使用权持续有效；若您将前述内容自本服务中删除，则使用权于删除时终止。</p>
          <p>（3）您同意已就您于本服务其他公开使用区域张贴的其他内容，授予完美玩伴免许可费、永久有效、不可撤销、非独家及可完全再授权之权利在全球使用、复制、修改、改写、改编、发行、翻译、创造衍生性著作，或将前述内容（部分或全部）加以散布、展示，或放入利用任何现在已知和未来开发出之形式、媒体和科技之其他著作物当中，同时您也同意完美玩伴将您的相关信息（包括照片、图形、影音资料、地理位置等内容）授权第三方合作伙伴使用，以便您获得更多的增值服务。当您怀疑可能构成侵犯时，您可以向完美玩伴指出。如果完美玩伴及其合作伙伴上使用了没有得到您（您是相应内容的拥有者）的许可的内容，您可以联系我们并出示能够证明您的所有权的证明请求移除相应内容。</p>
        </div>
      </div>

      <div class="section">
        <h2>8、信息的储存及限制</h2>
        <p>您同意关于本服务的使用完美玩伴有权制订一般措施及限制，包含但不限于本服务将保留的电子邮件讯息、所张贴内容或其他上载内容之最长期间、每个帐号可收发电子邮件讯息的最大数量及可收发的单个消息的大小。通过本服务存储或传送之任何信息、通讯资料和其他内容，如被删除或未予储存，完美玩伴毋须承担任何责任。您亦同意，长时间未使用的帐号，完美玩伴有权关闭。您也同意，完美玩伴有权依其自行之考量，不论通知与否，随时变更这些一般措施及限制。</p>
      </div>

      <div class="section">
        <h2>9、结束服务</h2>
        <p>用户或完美玩伴可随时根据实际情况中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对完美玩伴服务不满，用户只有以下的追索权：</p>
        <div class="list">
          <p>（1）不再使用完美玩伴服务。</p>
          <p>（2）结束用户使用完美玩伴服务的资格。</p>
          <p>（3）通告完美玩伴停止该用户的服务。</p>
        </div>
        <p>结束用户服务后，用户使用完美玩伴服务的权利马上中止。从那时起，完美玩伴不再对用户承担任何义务。</p>
      </div>

      <div class="section">
        <h2>10、免责声明</h2>
        <div class="disclaimer">
          <p>用户理解并接受：完美玩伴不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性、安全性、出错发生都不作担保。</p>
          <p>用户理解并接受：下载或通过完美玩伴产品服务取得的任何信息资料取决于用户自己，并由其承担系统受损或资料丢失的所有风险和责任。</p>
          <p>用户理解并接受：完美玩伴不对其他用户所提供的内容的准确性、时效性或完整性作任何保证，对于因使用这些资料或无法使用这些资料而导致的任何损害，完美玩伴无须承担法律责任。同时对于使用本网站链接的其他网站或网页而引起的任何损害，完美玩伴不承担任何法律责任。</p>
          <p>用户理解并接受：用户以自己的独立判断从事与交友相关的行为，并独立承担可能产生的责任，完美玩伴不承担任何法律责任。</p>
          <p>完美玩伴对直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用产品服务，在网上购买商品或类似服务，在网上进行交易，非法使用服务或用户传送的信息有所变动。</p>
          <p>完美玩伴对所有用户自发组织的活动不负责任。</p>
        </div>
      </div>
      <div class="section">
        <h2>11、赔偿</h2>
        <p>由于您通过本服务提供、张贴或传送之内容、您违反本服务条款、或您侵害他人任何权利因而衍生或导致任何第三人提出任何索赔或请求，包括合理的律师费，您同意赔偿完美玩伴公司并使其免受损害。</p>
      </div>

      <div class="section">
        <h2>12、禁止商业行为</h2>
        <p>您同意不对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其他商业目的。</p>
      </div>

      <div class="section">
        <h2>13、服务的修改和终止</h2>
        <p>完美玩伴将尽力提供最好的服务并在发生任何修改或者中断服务时及时通知用户，但是完美玩伴保留以下权利：</p>
        <div class="list">
          <p>（1）随时修改或中断服务而不需通知用户的权利。用户接受完美玩伴行使修改或中断服务的权利，完美玩伴不需对用户或第三方负责。</p>
          <p>（2）在必要时修改服务条款而不需通知用户的权利。</p>
        </div>
      </div>

      <div class="section">
        <h2>14、法律</h2>
        <p>用户和完美玩伴一致同意有关服务条款以及使用完美玩伴的服务产生的争议交由上海仲裁委员会解决。若有任何服务条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响。</p>
      </div>

      <div class="section">
        <h2>15、其他</h2>
        <p>服务条款最终解释权和修改权归完美玩伴所有。</p>
        <p>完美玩伴将视向用户所提供服务内容之特性，要求用户在注册完美玩伴提供的有关服务时，遵守特定的条件和条款；如该等条件和条款与以上服务条款有任何不一致之处，则以该等条件和条款为准。</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyTerms'
}
</script>

<style scoped>
.privacy-terms {
  padding: 20px 40px;
  background-color: #eeeeee;
  min-height: 820px;
}

.terms-container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
  color: #333;
}

.section {
  margin-bottom: 30px;
}

h2 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 10px;
  text-align: justify;
}
</style>